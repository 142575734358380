import React, {useEffect, useState} from "react";
import { Row, Container, Card, CardBody, CardHeader, Col } from "reactstrap";
import DataTable from 'react-data-table-component';
import { apiCall } from "../../helpers/api_backend_helper";
import moment from 'moment';
import { numberFormat } from "../../helpers/utils";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const TopUps = () => {
    const [payments, setPayments] = useState([]);
    document.title = "Dashboard | Inocrate";
    const columns = [
        {
            name: <span className='font-weight-bold fs-13'>Date</span>,
            sortable: true,
            selector: row => moment(row.date_added).format('DD.MM.YYYY HH:mm a') 
        },
        {
            name: <span className='font-weight-bold fs-13'>Amount</span>,
            selector: row => numberFormat(row.amount, 0, 'yes'),
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Status</span>,
            selector: row => row.status === 'success'? 'Success': 'Failed',
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Transaction Method</span>,
            selector: row => row.payment_type === 'portal'? 'Manual Top Up':row.payment_type,
            sortable: true
        }
    ];

    useEffect(() => {
        apiCall({}, 'get', `payments/?status=success`).then((response) => {
            if (response && response.count > 0) {
                setPayments(response.results);
            }
        }).catch((error) => error);
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Inocrate" breadcrumbItem="SMS Top Ups" />
                    <Row> 
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <DataTable
                                        columns={columns}
                                        data={payments}
                                        pagination
                                        responsive
                                        pointerOnHover
                                        striped
                                        dense
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default TopUps;


