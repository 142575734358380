import React, { useEffect, useState } from "react";
import { Row, Container, Card, CardBody, CardHeader, Col } from "reactstrap";
import DataTable from 'react-data-table-component';
import { apiCall } from "../../helpers/api_backend_helper";
import moment from 'moment';
import { numberFormat } from "../../helpers/utils";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const SentSMS = () => {
    document.title = "Dashboard | Inocrate";
    const [sentSms, setSentSMS] = useState([]);

    const columns = [
        {
            name: <span className='font-weight-bold fs-13'>Recipient.</span>,
            selector: row => row.recipient,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Message</span>,
            selector: row => row.message,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>SMS Cost</span>,
            selector: row => numberFormat(row.sms_cost, 0, 'yes'),
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Date</span>,
            selector: row => moment(row.date_added).format('DD.MM.YYYY HH:mm a'),
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Status</span>,
            sortable: true,
            selector: (cell) => {
                switch (cell.status) {
                    case "sent":
                        return <span className="badge badge-soft-success"> Sent </span>;
                    case "pending":
                        return <span className="badge badge-soft-warning"> Pending</span>;
                    case "failed":
                        return <span className="badge badge-soft-danger"> Failed </span>;
                    default:
                        return <span className="badge badge-soft-success"> None Status </span>;
                }
            },
        }
    ];

    useEffect(() => {
        apiCall({}, 'get', `sms-transactions/?sent_via=portal`).then((response) => {
            if (response && response.count > 0) {
                setSentSMS(response.results);
            }
        }).catch((error) => error);
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Inocrate" breadcrumbItem="Sent SMS" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <DataTable
                                        columns={columns}
                                        data={sentSms}
                                        pagination
                                        responsive
                                        pointerOnHover
                                        striped
                                        dense
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default SentSMS;


