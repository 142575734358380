import { useIdleTimer } from 'react-idle-timer'
import { getLoggedinUser } from '../../helpers/api_helper';
import { useNavigate } from 'react-router-dom';

const SESSION_IDEL_MINUTES = 4;

const SessionTimeout = () => {
  let history = useNavigate();

  const handleOnIdle = () => {
    if (getLoggedinUser()) {
      localStorage.removeItem("authUser");
    }
    history('/login');
  }

  useIdleTimer({
    timeout: 1000 * 60 * SESSION_IDEL_MINUTES,
    onIdle: handleOnIdle,
    debounce: 500,
    crossTab: true,
    leaderElection: true,
    syncTimers: 1000 * 60
  })
  return null;
};

export default SessionTimeout;