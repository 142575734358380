import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getLoggedinUser } from "../../helpers/api_helper";

const Navdata = (setSendSingleSMS, setSendBulkSMS) => {
  const [ui, setui] = useState(false);
  const [app, setapp] = useState(false);
  const [email, setemail] = useState(false);
  const [component, setcomponent] = useState(false);
  const [form, setform] = useState(false);
  const [table, settable] = useState(false);
  const [chart, setchart] = useState(false);
  const [icon, seticon] = useState(false);
  const [map, setmap] = useState(false);
  const [pages, setpages] = useState(false);
  let history = useNavigate();
  const currentUser = getLoggedinUser();

  const NavnavData = [
    {
      id: 1,
      label: "Dashboard",
      icon: "mdi mdi-home-variant-outline me-2",
      isdropDown: true,
      click: function () {
        setui(false);
        setapp(false);
        setcomponent(false);
        setpages(false);
      },
    },

    {
      id: 3,
      label: "Send SMS",
      icon: "ri-apps-2-line me-2",
      click: function () {
        setcomponent(!component);
        setui(false);
        setapp(false);
        setpages(false);
        setmap(false);
        setform(false);
        settable(false);
        setchart(false);
        seticon(false);
      },

      currentState: app,
      subItems: [
        {
          label2: "Single SMS",
          url: "/#",
          staclick: function (e) {
            e.preventDefault();
            setSendSingleSMS(true);
          }
        },
        {
          label2: "Bulk SMS",
          url: "/#",
          staclick: function (e) {
            e.preventDefault();
            setSendBulkSMS(true);
          }
        },
        // {
        //   label2: "Bulk Upload SMS",
        //   url: "/#",
        // },
      ],
    },
    {
      id: 4,
      label: "SMS Reports",
      icon: "ri-stack-line me-2",
      click: function () {
        setcomponent(!component);
        setui(false);
        setapp(false);
        setpages(false);
        setmap(false);
        setform(false);
        settable(false);
        setchart(false);
        seticon(false);
      },
      currentState: app,
      subItems: [
        {
          label2: "Web Sent SMS",
          url: "/sent-sms",
        },
        {
          label2: "API Sent SMS",
          url: "/sent-api-sms",
        }
      ]
    },
    {
      id: 1,
      label: "Payment/Top ups",
      icon: "ri-file-copy-2-line me-2",
      isdropDown: true,
      click: function (e) {
        setui(false);
        setapp(false);
        setcomponent(false);
        setpages(false);
        e.preventDefault();
        history('/payment-top-ups');
      },
    },

  ];

  if (currentUser && parseInt(currentUser.user.id) === 1) {
    NavnavData.push(
      {
        id: 1,
        label: "Companies",
        icon: " fas fa-archway me-2",
        isdropDown: true,
        click: function (e) {
          setui(false);
          setapp(false);
          setcomponent(false);
          setpages(false);
          e.preventDefault();
          history('/companies');
        },
      },
    )
  }

  return <React.Fragment>{NavnavData}</React.Fragment>;
};

export default Navdata;
