
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  UncontrolledAlert
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import withRouter from "../../components/Common/withRouter";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/placeholder-image.jpeg";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions";

import { apiCall } from "../../helpers/api_backend_helper";

const UserProfile = () => {
  document.title = "Profile | pushsms";

  const dispatch = useDispatch();

  const [userData, setData] = useState({});
  const [updateCompany, setUpdateCompany] = useState(false);

  const { error, success } = useSelector((state) => ({
    error: state.profile.error,
    success: state.profile.success,
  }));

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      setData(obj.user);

      setTimeout(() => {
        dispatch(resetProfileFlag());
      }, 3000);
    }
  }, [dispatch, success]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      company_name: userData.company_name || "",
      full_name: userData.full_name || "",
      username: userData.username || "",
      phone_number: userData.telephone || "",
      password: "",
      company_id: userData.company_id || "",
      id: userData.id || ""
    },
    validationSchema: Yup.object({
      company_name: Yup.string().required("Please Enter Company Name"),
    }),
    onSubmit: (values) => {
      apiCall({ company_name: values.company_name, telephone: values.phone_number }, 'patch', `companies/${values.company_id}/`).then((response) => {
        if (response) {
          const data = {
            full_name: values.company_name,
            first_name: values.company_name,
            last_name: values.company_name,
            telephone: values.phone_number
          }
          if (values.password) {
            data.password = values.password;
          }
          apiCall(data, 'patch', `users/${values.id}/`).then((usersResponse) => {
            if (usersResponse) {
              setUpdateCompany(true);
            }
          }).catch((error) => error);
        }
      }).catch((error) => error);
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Inocrate" breadcrumbItem="Profile" />

          <Row>
            <Col lg="12">
              {error && error ? (
                <Alert color="danger">
                  <div>{error}</div>
                </Alert>
              ) : null}
              {success ? (
                <Alert color="success">
                  <div>{success}</div>
                </Alert>
              ) : null}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail mr-2"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5 className="ml-4">{userData.company_name}</h5>
                        <p className="mb-1 ml-4">{userData.full_name}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Change User Details</h4>

          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="form-group">
                  <Label className="form-label">Company Name</Label>
                  <Input
                    name="company_name"
                    // value={name}
                    className="form-control"
                    placeholder="Enter Company Name"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.company_name || ""}
                    invalid={
                      validation.touched.company_name &&
                        validation.errors.company_name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.company_name &&
                    validation.errors.company_name ? (
                    <FormFeedback type="invalid">
                      <div>{validation.errors.company_name}</div>
                    </FormFeedback>
                  ) : null}
                </div>

                <div className="form-group mt-2">
                  <Label className="form-label">Phone Number</Label>
                  <Input
                    name="phone_number"
                    // value={name}
                    className="form-control"
                    placeholder="Enter Phone Number"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.phone_number || ""}
                    invalid={
                      validation.touched.phone_number &&
                        validation.errors.phone_number
                        ? true
                        : false
                    }
                  />
                  {validation.touched.phone_number &&
                    validation.errors.phone_number ? (
                    <FormFeedback type="invalid">
                      <div>{validation.errors.phone_number}</div>
                    </FormFeedback>
                  ) : null}
                </div>

                <div className="form-group mt-2">
                  <Label className="form-label">User Name (<span className="text-sm text-danger">Readonly</span>)</Label>
                  <Input
                    name="username"
                    // value={name}
                    className="form-control"
                    placeholder="Enter User Name"
                    type="text"
                    disabled
                    value={validation.values.username || ""}
                  />
                </div>

                <div className="form-group mt-2">
                  <Label className="form-label">Password <span className="text-sm text-success">Leave it empty to mantain current One</span> </Label>
                  <Input
                    name="password"
                    // value={name}
                    className="form-control"
                    placeholder="Enter New Password"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.password || ""}
                    invalid={
                      validation.touched.password &&
                        validation.errors.password
                        ? true
                        : false
                    }
                  />
                  {validation.touched.password &&
                    validation.errors.password ? (
                    <FormFeedback type="invalid">
                      <div>{validation.errors.password}</div>
                    </FormFeedback>
                  ) : null}
                </div>
                {
                  updateCompany &&
                  <UncontrolledAlert color="success">
                    <strong>Well done!</strong> Updated successfully, Please logout and login to see changes.
                  </UncontrolledAlert>
                }
                <div className="text-center mt-4">
                  <Button type="submit" color="success">
                    Update
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
