import React from "react";
import { Bar } from "react-chartjs-2";
import { Col, Card, CardBody } from "reactstrap";

const option = {
  responsive: true,
  maintainAspectRatio: true,
  scales: {
    dataset: [
      {
        display: false,
        gridLines: {
          drawBorder: false,
          offset: true,
        },
        ticks: {
          fontColor: "#686868",
        },
      },
    ],
    dataset1: [
      {
        barPercentage: 0.7,
        categoryPercentage: 0.5,

        ticks: {
          fontColor: "#7b919e",
        },
        gridLines: {
          display: false,
          drawBorder: false,
        },
        elements: {
          point: {
            radius: 0

          }
        }
      },
    ],
  },
};

const BarChart = (props) => {
  const { smsCountReport } = props;

  const data = {
    labels: smsCountReport.labels,
    datasets: [
      {
        label: "Total",
        data: smsCountReport.total,
        backgroundColor: "#3d8ef8",
        barThickness: 10,
      },
      {
        label: "Success",
        data: smsCountReport.success,
        backgroundColor: "#11c46e",
        barThickness: 10,
  
      },
      {
        label: "Failed",
        data: smsCountReport.failed,
        backgroundColor: "#f46a6a",
        barThickness: 10,
      }
      
    ],
  };

  return (
    <React.Fragment>
      <Col xl={8}>
        <Card>
          <CardBody>
            <Bar width={537} height={260} data={data} options={option} />
          </CardBody>
        </Card>
      </Col>

    </React.Fragment>
  );
};

export default BarChart;
