import React, { useState } from "react";
import {
    Row,
    Col,
    FormGroup,
    Label,
    Input,
    FormFeedback,
    Form,
    Button,
    UncontrolledAlert,
    Modal
} from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { apiCall } from "../../helpers/api_backend_helper";

const SingleSMSModal = (props) => {
    const { sendSingleSMS, setSendSingleSMS } = props;
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    const [toast, setToast] = useState(false);
    // Form validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            phoneNumber: "",
            message: ""
        },
        validationSchema: Yup.object({
            phoneNumber: Yup.string().required("Please Enter Phone Number")
                .matches(phoneRegExp, 'Phone number is not valid')
                .min(10, "Min Invalid number")
                .max(10, "Max Invalid number"),
            message: Yup.string().required("Please Enter Message")
        }),
        onSubmit: (values, { resetForm }) => {
            setToast(false);

            apiCall({ phone_numbers: values.phoneNumber, message: values.message }, 'post', `portal-bulksms/`).then((response) => {
                if (response) {
                    setToast(true);
                    resetForm();
                }
            }).catch((error) => error);
        },
    });

    return (
        <React.Fragment>
            <Modal
                size="lg"
                isOpen={sendSingleSMS}
                toggle={() => {
                    setSendSingleSMS(!sendSingleSMS);
                }}
                centered
            >
                <div className="modal-header">
                    <h5
                        className="modal-title mt-0"
                        id="myLargeModalLabel"
                    >
                        Send Single SMS
                    </h5>
                    <button
                        onClick={() => {
                            setSendSingleSMS(false);
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Form
                        className="needs-validation"
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <Row>
                            <Col md="12">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="validationPhoneNumber">Phone Number</Label>
                                    <Input
                                        name="phoneNumber"
                                        placeholder="Enter phone number eg 070XXXXXXXXXX"
                                        type="tel"
                                        className="form-control"
                                        id="validationPhoneNumber"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.phoneNumber || ""}
                                        invalid={
                                            validation.touched.phoneNumber &&
                                                validation.errors.phoneNumber
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.phoneNumber &&
                                        validation.errors.phoneNumber ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.phoneNumber}
                                        </FormFeedback>
                                    ) : null}
                                </FormGroup>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="validationPhoneNumber">Message ( <small className="text-danger">Max: 200 characters</small> ) </Label>
                                    <textarea
                                        name="message"
                                        placeholder="Enter your message"
                                        rows="5"
                                        maxLength='200'
                                        required
                                        className="form-control"
                                        id="validationMessage"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.message || ""}
                                        invalid={
                                            validation.touched.message &&
                                                validation.errors.message
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.message &&
                                        validation.errors.message ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.message}
                                        </FormFeedback>
                                    ) : null}
                                </FormGroup>
                                {
                                    toast &&
                                    <UncontrolledAlert color="success">
                                        <strong>Well done!</strong> Message sent successfully.
                                    </UncontrolledAlert>
                                }

                            </Col>
                        </Row>
                        <div className="d-flex flex-wrap gap-2 text-right">
                            <Button type="submit" color="primary" className="">
                                Send
                            </Button>{" "}
                            <Button type="reset" color="secondary" className="" onClick={() => {
                                setSendSingleSMS(false);
                            }}>
                                Cancel
                            </Button>
                        </div>
                    </Form>
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default SingleSMSModal;


