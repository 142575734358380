import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { numberFormat } from "../../helpers/utils";
import RadialChart1 from "./userpanelChart1";
import RadialChart2 from "./userpanelChart2";
import RadialChart3 from "./userpanelChart3";

const UserPanel = (props) => {

  const { companyDetails, smsCountReport } = props;
  return (
    <React.Fragment>
      <Row>

        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <div className="avatar-sm">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="bx bx-folder-open"></i>
                    </div>
                  </div>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1"> A/C Balance</p>
                  <h5 className="mb-3">{companyDetails && companyDetails.sms_balance && numberFormat(companyDetails.sms_balance.sms_count)}</h5>
                  <p className="text-truncate mb-0">
                    <span className="text-success me-2">
                      {" "}
                      {companyDetails && companyDetails.sms_balance && numberFormat(companyDetails.sms_balance.amount, 0, 'yes')}{" "}
                      <i className="ri-arrow-right-up-line align-bottom ms-1"></i>
                    </span>{" "}
                    Total Amount
                  </p>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <div className="avatar-sm">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="bx bx-folder-open"></i>
                    </div>
                  </div>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">Total SMS Sent  </p>
                  <h5 className="mb-3">{smsCountReport && smsCountReport.total && numberFormat(smsCountReport.total)}</h5>
                  <p className="text-truncate mb-0">
                    <span className="text-success me-2">
                      {" "}
                      {smsCountReport && smsCountReport.total_cost && numberFormat(smsCountReport.total_cost, 0, 'yes')}{" "}
                      <i className="ri-arrow-right-up-line align-bottom ms-1"></i>
                    </span>{" "}
                    Total Cost
                  </p>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <RadialChart2
                    id="radialchart-2"
                    className="apex-charts"
                    dir="ltr"
                    smsCountReport = {smsCountReport}
                  />
                </div>

                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">Success</p>
                  <h5 className="mb-3">{smsCountReport && smsCountReport.total && numberFormat(smsCountReport.sent)}</h5>
                  <p className="text-truncate mb-0">
                    <span className="text-success me-2">
                      {" "}
                      {smsCountReport && smsCountReport.total && numberFormat(smsCountReport.total_sent_cost, 0, 'yes')}{" "}
                      <i className="ri-arrow-right-up-line align-bottom ms-1"></i>
                    </span>{" "}
                    Total Cost
                  </p>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <RadialChart3
                    id="radialchart-3"
                    className="apex-charts"
                    dir="ltr"
                    smsCountReport = {smsCountReport}
                  />
                </div>

                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">Failed</p>
                  <h5 className="mb-3">{smsCountReport && smsCountReport.total && numberFormat(smsCountReport.failed)}</h5>
                  <p className="text-truncate mb-0">
                    <span className="text-danger me-2">
                      {" "}
                      {smsCountReport && smsCountReport.total && numberFormat(smsCountReport.total_failed_cost, 0,'yes')}{" "}
                      <i className="ri-arrow-right-down-line align-bottom ms-1"></i>
                    </span>{" "}
                    Reversed
                  </p>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

      </Row>
    </React.Fragment>
  );
};

export default UserPanel;
