import React, { useEffect, useState } from "react";
import {
    Row, Container, Card, CardBody, Col, Modal,
    FormGroup,
    Label,
    Input,
    FormFeedback,
    Form,
    Button,
    UncontrolledAlert
} from "reactstrap";
import DataTable from 'react-data-table-component';
import { apiCall } from "../../helpers/api_backend_helper";
import moment from 'moment';
import { numberFormat } from "../../helpers/utils";
import { getLoggedinUser } from "../../helpers/api_helper";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const Companies = () => {
    const [companies, setCompanies] = useState([]);
    const [addNewCompany, setAddNewCompany] = useState(false);
    const [updateCompany, setUpdateCompany] = useState({ isOpen: false, company: '', toast: false });
    const [reload, setReload] = useState(false);
    const currentUser = getLoggedinUser();
    let history = useNavigate();

    const [addCompanyTopUp, setAddCompanyTopUp] = useState({ isOpen: false, company_id: '', toast: false, sms_cost: 0 });
    const [deleteCompany, setDeleteCompany] = useState({ isOpen: false, company_id: '' });

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    const [toast, setToast] = useState(false);
    // Form validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            companyName: "",
            phoneNumber: "",
            smsCost: "",
            Username: "",
            Password: "",
            serviceProvider: ""
        },
        validationSchema: Yup.object({
            companyName: Yup.string().required("Please Enter Company Name"),
            phoneNumber: Yup.string().required("Please Enter Phone Number")
                .matches(phoneRegExp, 'Phone number is not valid')
                .min(10, "Min Invalid number")
                .max(14, "Max Invalid number"),
            smsCost: Yup.string().required("Please Enter SMS Cost"),
            Username: Yup.string().required("Please Enter Username"),
            Password: Yup.string().required("Please Enter Password"),
            serviceProvider: Yup.string().required("Please Enter Service Provider")
        }),
        onSubmit: (values, { resetForm }) => {
            setToast(false);
            apiCall({ company_name: values.companyName, telephone: values.phoneNumber, sms_cost: values.smsCost, service_provider: values.serviceProvider }, 'post', `companies/`).then((response) => {
                if (response) {
                    const data = {
                        username: values.Username,
                        is_superuser: false,
                        is_active: true,
                        full_name: values.companyName,
                        company: response.id,
                        first_name: values.companyName,
                        last_name: values.companyName,
                        password: values.Password,
                        telephone: values.phoneNumber
                    }
                    apiCall(data, 'post', `users/`).then((usersResponse) => {
                        if (usersResponse) {
                            setToast(true);
                            resetForm();
                            setReload(!reload);
                        }
                    }).catch((error) => error);
                }
            }).catch((error) => error);
        },
    });

    const updateValidation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            companyName: updateCompany.company ? updateCompany.company.company_name : "",
            phoneNumber: updateCompany.company ? updateCompany.company.telephone : "",
            smsCost: updateCompany.company ? updateCompany.company.sms_cost : "",
            Username: updateCompany.company ? updateCompany.company.company_user.username : "",
            serviceProvider: updateCompany.company ? updateCompany.company.service_provider : "",
            Password: ""
        },
        validationSchema: Yup.object({
            companyName: Yup.string().required("Please Enter Company Name"),
            phoneNumber: Yup.string().required("Please Enter Phone Number")
                .matches(phoneRegExp, 'Phone number is not valid')
                .min(10, "Min Invalid number")
                .max(14, "Max Invalid number"),
            smsCost: Yup.string().required("Please Enter SMS Cost"),
            Username: Yup.string().required("Please Enter Username"),
            serviceProvider: Yup.string().required("Please Enter Service Provider")
        }),
        onSubmit: (values) => {
            setUpdateCompany({ ...updateCompany, toast: false });
            apiCall({ company_name: values.companyName, telephone: values.phoneNumber, sms_cost: values.smsCost, service_provider: values.serviceProvider }, 'patch', `companies/${updateCompany.company.id}/`).then((response) => {
                if (response) {
                    const data = {
                        username: values.Username,
                        is_superuser: false,
                        is_active: true,
                        full_name: values.companyName,
                        company: response.id,
                        first_name: values.companyName,
                        last_name: values.companyName,
                        telephone: values.phoneNumber
                    }
                    if (values.Password) {
                        data.password = values.Password;
                    }
                    apiCall(data, 'patch', `users/${updateCompany.company.company_user.id}/`).then((usersResponse) => {
                        if (usersResponse) {
                            setUpdateCompany({ ...updateCompany, toast: true });
                            setReload(!reload);
                        }
                    }).catch((error) => error);
                }
            }).catch((error) => error);
        },
    });


    // Form validation
    const topUpValidation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            Amount: "",
        },
        validationSchema: Yup.object({
            Amount: Yup.string().required("Please Enter Amount"),
        }),
        onSubmit: (values, { resetForm }) => {
            setAddCompanyTopUp({ ...addCompanyTopUp, toast: false });
            apiCall({ amount: values.Amount, sms_cost: addCompanyTopUp.sms_cost, company: addCompanyTopUp.company_id }, 'post', `payments/`).then((response) => {
                if (response) {
                    setAddCompanyTopUp({ ...addCompanyTopUp, toast: true });
                    resetForm();
                    setReload(!reload);
                }
            }).catch((error) => error);
        },
    });

    const handleDelete = () => {
        apiCall({}, 'delete', `companies/${deleteCompany.company_id}`).then((response) => {
            setDeleteCompany({ ...deleteCompany, isOpen: false });
            setReload(!reload);
        }).catch((error) => error);
    }

    document.title = "Dashboard | Inocrate";
    const columns = [
        {
            name: <span className='font-weight-bold fs-13'>Name</span>,
            sortable: true,
            selector: row => <div>{row.company_name} <br />(<span className="text-success">{row.company_user.username}</span>)</div>
        },
        {
            name: <span className='font-weight-bold fs-13'>Telephone</span>,
            selector: row => row.telephone,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>SMS Cost</span>,
            selector: row => numberFormat(row.sms_cost),
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>A/C Balance</span>,
            selector: row => numberFormat(row.sms_balance.amount),
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>No of SMS</span>,
            selector: row => numberFormat(row.sms_balance.sms_count),
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Action</span>,
            selector: row => <div style={{ display: 'flex' }}>
                <button
                    style={{ float: 'left' }}
                    type="button"
                    className="btn btn-primary waves-effect waves-light btn-sm"
                    onClick={() => {
                        setAddCompanyTopUp({ ...addCompanyTopUp, isOpen: true, company_id: row.id, sms_cost: row.sms_cost });
                    }}
                >
                    TopUp
                </button>

                <button
                    style={{ float: 'left', marginLeft: '4px' }}
                    type="button"
                    className="btn btn-secondary waves-effect waves-light btn-sm"
                    onClick={() => {
                        setUpdateCompany({ ...updateCompany, isOpen: true, company: row });
                    }}
                >
                    Edit
                </button>
                <button
                    style={{ float: 'left', marginLeft: '4px' }}
                    disabled={(currentUser.user && parseInt(currentUser.user.company_id) === parseInt(row.id)) ? true : ''}
                    type="button"
                    className="btn btn-danger waves-effect waves-light ml-4 btn-sm"
                    onClick={() => {
                        setDeleteCompany({ ...deleteCompany, isOpen: true, company_id: row.id });
                    }}
                >
                    Delete
                </button>

            </div>,
            sortable: true
        }
    ];

    useEffect(() => {
        if (currentUser && parseInt(currentUser.user.id) !== 1) {
            history('/dashboard');
        }
        apiCall({}, 'get', `companies/`).then((response) => {
            if (response && response.count > 0) {
                setCompanies(response.results);
            }
        }).catch((error) => error);

    }, [reload]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Inocrate" breadcrumbItem="Companies" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <div className="align-right">
                                        <button
                                            style={{ float: 'right' }}
                                            type="button"
                                            className="btn btn-primary waves-effect waves-light mb-2 btn-sm"
                                            onClick={() => {
                                                setAddNewCompany(true);
                                            }}
                                        >
                                            <i className="ri-arrow-right-line align-middle ms-2"></i>
                                            {" "} Add New Company
                                        </button>
                                    </div>

                                    <DataTable
                                        columns={columns}
                                        data={companies}
                                        pagination
                                        responsive
                                        pointerOnHover
                                        striped
                                        dense
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Modal
                    size="lg"
                    isOpen={addNewCompany}
                    toggle={() => {
                        setAddNewCompany(!addNewCompany);
                    }}
                    centered
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="myLargeModalLabel"
                        >
                            Add New Company
                        </h5>
                        <button
                            onClick={() => {
                                setAddNewCompany(false);
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Form
                            className="needs-validation"
                            onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}
                        >
                            <Row>
                                <Col md="12">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="validationcompanyName">Company Name</Label>
                                        <Input
                                            name="companyName"
                                            placeholder="Enter Company Name"
                                            type="text"
                                            className="form-control"
                                            id="validationcompanyName"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.companyName || ""}
                                            invalid={
                                                validation.touched.companyName &&
                                                    validation.errors.companyName
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validation.touched.companyName &&
                                            validation.errors.companyName ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.companyName}
                                            </FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="validationPhoneNumber">Phone Number</Label>
                                        <Input
                                            name="phoneNumber"
                                            placeholder="Enter phone number eg 070XXXXXXXXXX"
                                            type="text"
                                            className="form-control"
                                            id="validationPhoneNumber"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.phoneNumber || ""}
                                            invalid={
                                                validation.touched.phoneNumber &&
                                                    validation.errors.phoneNumber
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validation.touched.phoneNumber &&
                                            validation.errors.phoneNumber ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.phoneNumber}
                                            </FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="validationSmsCost">SMS cost </Label>
                                        <Input
                                            name="smsCost"
                                            placeholder="Enter SMS Cost"
                                            type="number"
                                            className="form-control"
                                            id="validationSmsCost"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.smsCost || ""}
                                            invalid={
                                                validation.touched.smsCost &&
                                                    validation.errors.smsCost
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validation.touched.smsCost &&
                                            validation.errors.smsCost ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.smsCost}
                                            </FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="validationUsername">Username </Label>
                                        <Input
                                            name="Username"
                                            placeholder="Enter Username"
                                            type="text"
                                            className="form-control"
                                            id="validationUsername"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.Username || ""}
                                            invalid={
                                                validation.touched.Username &&
                                                    validation.errors.Username
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validation.touched.Username &&
                                            validation.errors.Username ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.Username}
                                            </FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="validationPassword">Password </Label>
                                        <Input
                                            name="Password"
                                            placeholder="Enter Password"
                                            type="password"
                                            className="form-control"
                                            id="validationPassword"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.Password || ""}
                                            invalid={
                                                validation.touched.Password &&
                                                    validation.errors.Password
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validation.touched.Password &&
                                            validation.errors.Password ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.Password}
                                            </FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="validationUsername">Service Provider </Label>
                                        <Input
                                            name="serviceProvider"
                                            placeholder="Enter Service Provider"
                                            type="text"
                                            className="form-control"
                                            id="validationUsername"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.serviceProvider || ""}
                                            invalid={
                                                validation.touched.serviceProvider &&
                                                    validation.errors.serviceProvider
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validation.touched.serviceProvider &&
                                            validation.errors.serviceProvider ? (
                                            <FormFeedback type="invalid">
                                                {validation.errors.serviceProvider}
                                            </FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                    {
                                        toast &&
                                        <UncontrolledAlert color="success">
                                            <strong>Well done!</strong> Company added successfully.
                                        </UncontrolledAlert>
                                    }

                                </Col>
                            </Row>
                            <div className="d-flex flex-wrap gap-2 text-right">
                                <Button type="submit" color="primary" className="">
                                    Save Now
                                </Button>{" "}
                                <Button type="reset" color="secondary" className="" onClick={() => {
                                    setAddNewCompany(false);
                                }}>
                                    Cancel
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Modal>

                <Modal
                    size="md"
                    isOpen={addCompanyTopUp.isOpen}
                    toggle={() => {
                        setAddCompanyTopUp({ ...addCompanyTopUp, isOpen: false });
                    }}
                    centered
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="myLargeModalLabel"
                        >
                            Add TopUp
                        </h5>
                        <button
                            onClick={() => {
                                setAddCompanyTopUp({ ...addCompanyTopUp, isOpen: false });
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Form
                            className="needs-validation"
                            onSubmit={(e) => {
                                e.preventDefault();
                                topUpValidation.handleSubmit();
                                return false;
                            }}
                        >
                            <Row>
                                <Col md="12">
                                    <FormGroup className="mb-4">
                                        <Label htmlFor="validationAmount">Amount</Label>
                                        <Input
                                            name="Amount"
                                            placeholder="Enter Amount"
                                            type="number"
                                            className="form-control"
                                            id="validationAmount"
                                            onChange={topUpValidation.handleChange}
                                            onBlur={topUpValidation.handleBlur}
                                            value={topUpValidation.values.Amount || ""}
                                            invalid={
                                                topUpValidation.touched.Amount &&
                                                    topUpValidation.errors.Amount
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {topUpValidation.touched.Amount &&
                                            topUpValidation.errors.Amount ? (
                                            <FormFeedback type="invalid">
                                                {topUpValidation.errors.Amount}
                                            </FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                    {
                                        addCompanyTopUp.toast &&
                                        <UncontrolledAlert color="success">
                                            <strong>Well done!</strong> TopUp added successfully.
                                        </UncontrolledAlert>
                                    }

                                </Col>
                            </Row>
                            <div className="d-flex flex-wrap gap-2 text-right">
                                <Button type="submit" color="primary" className="">
                                    Save Now
                                </Button>{" "}
                                <Button type="reset" color="secondary" className="" onClick={() => {
                                    setAddCompanyTopUp({ ...addCompanyTopUp, isOpen: false });
                                }}>
                                    Cancel
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Modal>

                <Modal
                    size="md"
                    isOpen={deleteCompany.isOpen}
                    toggle={() => {
                        setDeleteCompany({ ...deleteCompany, isOpen: false });
                    }}
                    centered
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="myLargeModalLabel"
                        >
                            Delete Company
                        </h5>
                        <button
                            onClick={() => {
                                setDeleteCompany({ ...deleteCompany, isOpen: false });
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        Are you sure, you want to delete this company?
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                            onClick={() => {
                                setDeleteCompany({ ...deleteCompany, isOpen: false });
                            }}
                        >
                            Cancel
                        </button>
                        <button type="button" className="btn btn-danger" onClick={() => {
                            handleDelete();
                        }}>
                            Yes, delete
                        </button>
                    </div>
                </Modal>


                <Modal
                    size="lg"
                    isOpen={updateCompany.isOpen}
                    toggle={() => {
                        setUpdateCompany({ ...updateCompany, isOpen: !updateCompany.isOpen });
                    }}
                    centered
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="myLargeModalLabel"
                        >
                            Update Company
                        </h5>
                        <button
                            onClick={() => {
                                setUpdateCompany({ ...updateCompany, isOpen: false });
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Form
                            className="needs-validation"
                            onSubmit={(e) => {
                                e.preventDefault();
                                updateValidation.handleSubmit();
                                return false;
                            }}
                        >
                            <Row>
                                <Col md="12">
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="validationcompanyName">Company Name</Label>
                                        <Input
                                            name="companyName"
                                            placeholder="Enter Company Name"
                                            type="text"
                                            className="form-control"
                                            id="validationcompanyName"
                                            onChange={updateValidation.handleChange}
                                            onBlur={updateValidation.handleBlur}
                                            value={updateValidation.values.companyName || ""}
                                            invalid={
                                                updateValidation.touched.companyName &&
                                                    updateValidation.errors.companyName
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {updateValidation.touched.companyName &&
                                            updateValidation.errors.companyName ? (
                                            <FormFeedback type="invalid">
                                                {updateValidation.errors.companyName}
                                            </FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="validationPhoneNumber">Phone Number</Label>
                                        <Input
                                            name="phoneNumber"
                                            placeholder="Enter phone number eg 070XXXXXXXXXX"
                                            type="text"
                                            className="form-control"
                                            id="validationPhoneNumber"
                                            onChange={updateValidation.handleChange}
                                            onBlur={updateValidation.handleBlur}
                                            value={updateValidation.values.phoneNumber || ""}
                                            invalid={
                                                updateValidation.touched.phoneNumber &&
                                                    updateValidation.errors.phoneNumber
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {updateValidation.touched.phoneNumber &&
                                            updateValidation.errors.phoneNumber ? (
                                            <FormFeedback type="invalid">
                                                {updateValidation.errors.phoneNumber}
                                            </FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="validationSmsCost">SMS cost </Label>
                                        <Input
                                            name="smsCost"
                                            placeholder="Enter SMS Cost"
                                            type="number"
                                            className="form-control"
                                            id="validationSmsCost"
                                            onChange={updateValidation.handleChange}
                                            onBlur={updateValidation.handleBlur}
                                            value={updateValidation.values.smsCost || ""}
                                            invalid={
                                                updateValidation.touched.smsCost &&
                                                    updateValidation.errors.smsCost
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {updateValidation.touched.smsCost &&
                                            updateValidation.errors.smsCost ? (
                                            <FormFeedback type="invalid">
                                                {updateValidation.errors.smsCost}
                                            </FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="validationUsername">Username </Label>
                                        <Input
                                            name="Username"
                                            placeholder="Enter Username"
                                            type="text"
                                            className="form-control"
                                            id="validationUsername"
                                            onChange={updateValidation.handleChange}
                                            onBlur={updateValidation.handleBlur}
                                            value={updateValidation.values.Username || ""}
                                            invalid={
                                                updateValidation.touched.Username &&
                                                    updateValidation.errors.Username
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {updateValidation.touched.Username &&
                                            updateValidation.errors.Username ? (
                                            <FormFeedback type="invalid">
                                                {updateValidation.errors.Username}
                                            </FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="validationPassword">New Password <span className="text-sm text-success">Leave it empty to mantain current One</span></Label>
                                        <Input
                                            name="Password"
                                            placeholder="Enter Password"
                                            type="password"
                                            className="form-control"
                                            id="validationPassword"
                                            onChange={updateValidation.handleChange}
                                            onBlur={updateValidation.handleBlur}
                                            value={updateValidation.values.Password || ""}
                                        />
                                    </FormGroup>
                                    <FormGroup className="mb-3">
                                        <Label htmlFor="validationUsername">Service Provider </Label>
                                        <Input
                                            name="serviceProvider"
                                            placeholder="Enter Service Provider"
                                            type="text"
                                            className="form-control"
                                            id="validationUsername"
                                            onChange={updateValidation.handleChange}
                                            onBlur={updateValidation.handleBlur}
                                            value={updateValidation.values.serviceProvider || ""}
                                            invalid={
                                                updateValidation.touched.serviceProvider &&
                                                    updateValidation.errors.serviceProvider
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {updateValidation.touched.serviceProvider &&
                                            updateValidation.errors.serviceProvider ? (
                                            <FormFeedback type="invalid">
                                                {updateValidation.errors.serviceProvider}
                                            </FormFeedback>
                                        ) : null}
                                    </FormGroup>

                                    {
                                        updateCompany.toast &&
                                        <UncontrolledAlert color="success">
                                            <strong>Well done!</strong> Company updated successfully.
                                        </UncontrolledAlert>
                                    }

                                </Col>
                            </Row>
                            <div className="d-flex flex-wrap gap-2 text-right">
                                <Button type="submit" color="primary" className="">
                                    Update Now
                                </Button>{" "}
                                <Button type="reset" color="secondary" className="" onClick={() => {
                                    setUpdateCompany({ ...updateCompany, isOpen: false });
                                }}>
                                    Cancel
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Modal>

            </div>
        </React.Fragment>
    );
};

export default Companies;


