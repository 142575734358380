import React, { useEffect, useState } from "react";
import { Row, Container } from "reactstrap";

import UsePanel from "./UserPanel";
import DountChart from '../AllCharts/chartjs/dountchart';
import BarChart from '../AllCharts/chartjs/barchart';
import { apiCall } from "../../helpers/api_backend_helper";
import { getLoggedinUser } from '../../helpers/api_helper';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const Dashboard = () => {
  document.title = "Dashboard | Inocrate";

  const [companyDetails, setCompanyDetails] = useState({});
  const [smsCountPieReport, setSmsCountPieReport] = useState({});
  const [smsCountBarReport, setSmsCountBarReport] = useState({});
  const [smsCountCardsReport, setSmsCountCardsReport] = useState({});

  useEffect(() => {
    const currentUser = getLoggedinUser();
    if (currentUser && currentUser.user.company_id) {
      apiCall({}, 'get', `companies/${currentUser.user.company_id}/`).then((response) => {
        if (response) {
          setCompanyDetails(response);
        }
      }).catch((error) => error);
    }

    apiCall({}, 'get', `sent-sms-report/`).then((response) => {
      if (response) {
        setSmsCountPieReport(response.pie_chart);
        setSmsCountBarReport(response.bar_chart);
        setSmsCountCardsReport(response.cards);
      }
    }).catch((error) => error);

  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true} className='main-container-view'>
          {/* <Breadcrumbs title="Inocrate" breadcrumbItem="Dashboard" /> */}
          {/* User Panel Charts */}
          <UsePanel companyDetails={companyDetails}  smsCountReport={smsCountCardsReport} />

          <Row>
            {/* Bar Chart */}
            <BarChart smsCountReport={smsCountBarReport} />
            {/* Social Source Chart */}
            <DountChart smsCountReport={smsCountPieReport}/>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;


