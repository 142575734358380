export const numberFormat = (inputNumber, dp = 0, currency = '') => {
    let formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: dp
    });
  
    if (currency === 'yes') {
       formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: dp,
        style: 'currency',
        currency: 'UGX',
      });
    }
  
    return formatter.format(inputNumber);
  }