import React from "react";
import ReactApexChart from "react-apexcharts";
import { Col, Card, CardBody, CardTitle } from "reactstrap";

const dountchart = (props) => {
  const { smsCountReport } = props;
  const dountchartData = {
    series: [smsCountReport.failed, smsCountReport.success, smsCountReport.pending],
    options: {
      labels: ["Failed", "Success/Sent", 'Queued/Pending'],
      colors: ["#f46a6a", "rgb(17, 196, 110)", "#f1b44c"],
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "center",
        verticalAlign: "middle",
        floating: false,
        fontSize: "14px",
        offsetX: 0,
        offsetY: -10,
      },
      responsive: [
        {
          breakpoint: 600,
          options: {
            chart: {
              height: 240,
            },
            legend: {
              show: false,
            },
          },
        },
      ],
    },
  }

  return (
    <React.Fragment>
      <Col xl={4}>
        <Card>
          <CardBody>
            <div className="d-flex  align-items-center">
              <div className="flex-grow-1">
                <h5 className="card-title"><b>{smsCountReport && smsCountReport.label}</b></h5>
              </div>
              <div className="flex-shrink-0">
                <select className="form-select form-select-sm mb-0 my-n1">
                  {smsCountReport && smsCountReport.plot_points && smsCountReport.plot_points.map((item, key) => (
                    <option key={key} value={item.selection}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {(smsCountReport && smsCountReport.success) &&
              <ReactApexChart
                options={dountchartData.options}
                series={dountchartData.series}
                type="donut"
                height="500"
                className="apex-charts"
              />
            }
          </CardBody>
        </Card>
      </Col>

    </React.Fragment>
  )
}

export default dountchart;


